import _ from 'underscore';
import parse from 'html-react-parser'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';

import React, { useRef, useEffect, useState } from "react";
import FeatureFilter from '@arcgis/core/layers/support/FeatureFilter';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Search from '@arcgis/core/widgets/Search';
import Locate from '@arcgis/core/widgets/Locate';
import Zoom from '@arcgis/core/widgets/Zoom';
import Slider from '@arcgis/core/widgets/Slider';
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import PopupTemplate from "@arcgis/core/PopupTemplate";
import Graphic from "@arcgis/core/Graphic";
import Circle from "@arcgis/core/geometry/Circle";
import Point from "@arcgis/core/geometry/Point";

import "./App.css";

let search = null;
let layer = null;
let view = null;
let map = null;
let zoom = null;
let slider = null;
let locate = null;
let filterLayerView = null;
let searchRadius = null;
let query = {};

function App() {
  const [filtered, setFiltered] = useState([]);
  const [showClear, setShowClear] = useState(false);
  const [openResults, setOpenResults] = useState(false);
  const [showingResult, setShowingResult] = useState(0);
  const [radius, setRadius] = useState(10);
  const [target, setTarget] = useState([0, 0]);
  const [category, setCategory] = useState('services');
  const [panelResults, setPanelResults] = useState([]);

  const zoomDiv = useRef(null);
  const sliderDiv = useRef(null);
  const locateDiv = useRef(null);
  const searchDiv = useRef(null);
  const mapCanvas = useRef(null);
  const appWrap = useRef(null);

  const filterServices = [
    { label: 'Case Management', value: 'case_management' },
    { label: 'Mental Health', value: 'mental_health_services' },
    { label: 'Serious Mental Health & Substance Abuse Treatment', value: 'serious_mh___sa_treatment' },
    { label: 'Outpatient Day Treatment', value: 'outpatient_day_treatment_or_par' },
    { label: 'Residential Detoxification', value: 'residential_detoxification' },
    { label: 'Relapse Prevention', value: 'relapse_prevention' },
    { label: 'Family Counseling', value: 'family_counseling' },
    { label: 'Group Counseling', value: 'group_counseling' },
    { label: 'Individual Counseling', value: 'individual_counseling' },
    { label: 'Couples Counseling', value: 'marital_couples_counseling' },
  ];
  const filterFinancial = [
    { label: 'Payment Assistance (Check With Facility)', value: 'payment_assistance__check_with_' },
    { label: 'Private Insurance Accepted', value: 'private_health_insurance' },
    { label: 'Medicaid Accepted', value: 'medicaid' },
    { label: 'Medicare Accepted', value: 'medicare' },
    { label: 'Military Insurance Accepted', value: 'military_insurance__e_g___trica' },
  ];
  const filterTreatment = [
    { label: 'Outpatient Medication Based', value: 'outpatient_medication_based_tre' },
    { label: 'Buprenorphine Used', value: 'buprenorphine_used_in_treatment' },
    { label: 'Detoxification', value: 'detoxification' },
    { label: 'Methadone Used', value: 'methadone_used_in_treatment' },
    { label: 'Naltrezone Used', value: 'naltrexone_used_in_treatment' },
    { label: '12-Step Facilitation', value: 'f12_step_facilitation' },
    { label: 'Anger Managment', value: 'anger_management' },
    { label: 'Matrix Model', value: 'matrix_model' },
    { label: 'Rational Emotive Behavioral Therapy', value: 'rational_emotive_behavioral_the' },
    { label: 'Motivational Interviewing', value: 'motivational_interviewing' },
    { label: 'Contigency Managment + Motivational Incentives', value: 'contingency_management_motivati' },
    { label: 'Community Reinforcement + Vouchers', value: 'community_reinforcement_plus_vo' },
  ];

  useEffect(() => {

    if (layer == null) {
      layer = new FeatureLayer({
        url: 'https://geo1.oit.ohio.gov/arcgis/rest/services/Hosted/Substance_Abuse_Treatment_Providers/FeatureServer/0',
        portalItem: {
          id: "fe2feff6b5d8405293c14d7aaa00b5e3"
        },
        outFields: ["*"],
        renderer: {
          type: "simple",
          symbol: {
            type: "simple-marker",
            style: "path",
            outline: { width: 0 },
            path: "M384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192H384z",
            color: [222, 121, 33, 1],
            size: 12,
          }
        },
        popupTemplate: {
          title: "{NAME}",
          content: popupContent,
          overwriteActions: true,
          dockEnabled: false,
          actions: [
            {
              id: "more-details",
              title: "See more details"
            }
          ]
        },
      });
    }

    if (map == null) {
      map = new Map({
        basemap: "topo-vector",
        layers: [layer],
      });
    }

    if (view == null) {
      view = new MapView({
        container: mapCanvas.current,
        map: map,
        center: [-82.9988, 39.9612],
        zoom: 6,
        ui: {
          components: ["attribution"],
        },
        popup: {
          dockEnabled: false,
          dockOptions: {
            buttonEnabled: false,
            breakpoint: false
          }
        },
        highlightOptions: {
          haloOpacity: 0,
          fillOpacity: 0,
        },
      });

      // Popup action for see more details
      view.popup.on("trigger-action", (event) => {
        if (event.action.id === "more-details") {
          //console.log(view.popup.selectedFeature);
          setOpenResults(true);
          setShowingResult(view.popup.selectedFeature.attributes.objectid);

          // Scroll into view
          setTimeout(function(){
            const section = document.querySelector('#feature-' + view.popup.selectedFeature.attributes.objectid);
            section.scrollIntoView();
          }, 100);
        }
      });

      // When view layer updates
      view.whenLayerView(layer)
        .then(function(layerView){
          filterLayerView = layerView;

          // Get results for side panel
          layerView.watch("updating", function(value) {
            if (!value) {
              layerView
                .queryFeatures(query)
                .then(function(results) {
                  //console.log(results.features);
                  setPanelResults(results.features);
                });
            }
          });

        })
        .catch(function(error) {
          console.log("error: ", error);
        });
    }

    // Widgets
    if (search == null) {
      search = new Search({
        container: searchDiv.current,
        view: view,
        popupEnabled: false,
        resultGraphicEnabled: false,
      });
      search.on(["search-complete"], function(event){
        var lat = event.results[0].results[0].feature.geometry.latitude;
        var lon = event.results[0].results[0].feature.geometry.longitude;
        setTarget([lon, lat]);
        setShowClear(true);
      });
      search.on(["search-clear"], function(event){
        view.graphics.removeAll();
        searchRadius = null;
        setTarget([0, 0]);
        setShowClear(false);
      });
    }
    if (locate == null) {
      locate = new Locate({
        view: view,
        popupEnabled: false,
        visible: false,
      });
      locate.on("locate", function(locateEvent){
        search.search([locateEvent.position.coords.longitude, locateEvent.position.coords.latitude]);
      });
    }
    if (slider == null) {
      slider = new Slider({
        container: sliderDiv.current,
        min: 5,
        max: 100,
        values: [radius],
        steps: 5,
        visibleElements: {
          labels: true,
          rangeLabels: true
        },
        labelFormatFunction: function(value, type) {
          return (type === "value") ? value.toFixed(0) + ' mi' : value + ' mi';
        },
        visible: false,
      });
      slider.on(["thumb-change", "thumb-click", "thumb-drag"], function(event){
        if (event.type == "thumb-drag" && event.state == "stop") {
          setRadius(event.value);
        } else if (event.type == "thumb-click") {
          setRadius(event.value);
        } else if (event.type == "thumb-change") {
          setRadius(event.value);
        }
      });
    }
    if (zoom == null) {
      zoom = new Zoom({
        container: zoomDiv.current,
        view: view,
      });
    }

    // Filters selected
    if (filterLayerView !== null) {

      // Create point
      if (!_(target).isEqual([0, 0])) {
        view.graphics.removeAll();
        searchRadius = new Circle({
          center: target,
          geodesic: true,
          numberOfPoints: 100,
          radius: radius,
          radiusUnit: "miles"
        });
        view.graphics.add(new Graphic({
          geometry: searchRadius,
          symbol: {
            type: "simple-fill",
            style: "none",
            outline: {
              width: 2,
              color: "#454545"
            }
          }
        }));
        slider.visible = true;
      } else {
        view.graphics.removeAll();
        searchRadius = null;
        slider.visible = false;
      }

      // Filter points based on queries
      if (searchRadius !== null) {
        query = {
          where: getQueryString(),
          geometry: searchRadius,
          spatialRelationship: "contains",
        };
        filterLayerView.filter = {
          where: getQueryString(),
          geometry: searchRadius,
          spatialRelationship: "contains",
        };
      } else {
        query = {
          where: getQueryString(),
        };
        filterLayerView.filter = {
          where: getQueryString(),
        };
      }
    }

  }, [filtered, target, radius]);

  function getQueryString() {
    let queryString = '';

    _.each(filtered, function(value, key, obj) {
      queryString = queryString + obj[key] + " = 'Yes' AND ";
    });
    queryString = queryString.substring(0, queryString.length - 5);

    return queryString;
  }

  function popupContent(feature) {
    //console.log(feature);
    const div = document.createElement("div");
    var address = feature.graphic.attributes.address;
    var phone = feature.graphic.attributes.phone;
    address = address.replaceAll(',', ', ');
    address = address.replace(', ', '<br>');
    address = address.replace('OH, ', 'OH ');
    div.innerHTML = "<p>" + address + "</p><p>" + phone + "</p>";
    return div;
  }

  function Checkbox(props) {
    const handleOnClick = () => {
      var newFiltered;

      // Remove category
      if (_.contains(filtered, props.value)) {
        newFiltered = _.without(filtered, props.value);
      } else { // Add category
        newFiltered = (prevFiltered) => [
          ...prevFiltered,
          props.value,
        ];
      }

      setFiltered(newFiltered);
    };

    return (
      <div className={[
        'checkbox',
        (category === props.category ? 'visible' : 'hidden'),
        (_.contains(filtered, props.value) ? 'checked' : null),
      ].join(' ')} onClick={handleOnClick}>
        <span>{props.label}</span>
        <div className="icon">
          {_.contains(filtered, props.value) ?
            <FontAwesomeIcon icon={solid('square-check')} />
          :
            <FontAwesomeIcon icon={regular('square')} />
          }
        </div>
      </div>
    );
  }

  function Result(props) {
    const [expand, setExpand] = useState(false);

    let address = props.data.address;
    address = address.replaceAll(',', ', ');
    address = address.replace(', ', '<br>');
    address = address.replace('OH, ', 'OH ');

    if (showingResult == props.data.objectid && !expand) {
      setExpand(true);
    }

    function handleExpandToggle() {
      if (expand) {
        setShowingResult(0);
      }
      setExpand(!expand);
    }

    return (
      <div id={'feature-' + props.data.objectid} className={expand ? "result active" : "result inactive"}>
        {expand ?
          <button className="toggleDetails" onClick={handleExpandToggle}>
            <FontAwesomeIcon icon={solid('circle-xmark')} />
          </button>
        :
          <button className="toggleDetails" onClick={handleExpandToggle}>
            <FontAwesomeIcon icon={solid('circle-info')} />
          </button>
        }
        <div className="name">
          {props.data.name}
        </div>
        <div className="address">
          {parse(address)}
        </div>
        <div className="phone">
          {props.data.phone}
        </div>
        {expand ? (
          <div className="details">
            {filterServices.map((value, i) => (
              <div className="detail" key={i}>
                {value.label}: <span>{(props.data[value.value] == 'Yes' ? 'Yes' : 'No')}</span>
              </div>
            ))}
            {filterFinancial.map((value, i) => (
              <div className="detail" key={i}>
                {value.label}: <span>{(props.data[value.value] == 'Yes' ? 'Yes' : 'No')}</span>
              </div>
            ))}
            {filterTreatment.map((value, i) => (
              <div className="detail" key={i}>
                {value.label}: <span>{(props.data[value.value] == 'Yes' ? 'Yes' : 'No')}</span>
              </div>
            ))}
          </div>
        ) : null }
      </div>
    );
  }

  function ResultsPanel() {
    return (
      <div className={openResults ? 'resultPanel show' : 'resultPanel hidden'}>
        <div className="heading">
          <span>
            Results: {panelResults.length}
          </span>
          <button onClick={() => setOpenResults(!openResults)}>
            <FontAwesomeIcon icon={solid('angle-right')} />
          </button>
        </div>
        <div className="results" id="resultsViewport">
          {panelResults.map((e, i) => (
            <Result key={i} data={e.attributes} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="appWrap" ref={appWrap}>
      <div className="card">
        <div className="search">
          <button className="submit" onClick={() => search.search()}>
            <FontAwesomeIcon icon={solid('magnifying-glass')} />
          </button>
          <div id="searchDiv" ref={searchDiv}></div>
          {showClear ?
            <button className="clear" onClick={() => search.clear()}>
              <FontAwesomeIcon icon={solid('circle-xmark')} />
            </button>
          : null }
          <button className="gps" onClick={() => locate.locate()}>
            <FontAwesomeIcon icon={solid('location-crosshairs')} />
          </button>
        </div>
        <div className="goToFilters">
          <a href="#categories" onClick={(e) => {
            e.preventDefault();
            let anchor = document.querySelector('#categories');
            anchor.scrollIntoView();
          }}>
            Filter by categories
          </a>
        </div>
        <div className="sides">
          <div className="left" id="categories">
            <div className="heading">Category</div>
            <div className="filterButtons">
              <button data-category="services" className={category === 'services' ? 'active' : null} onClick={() => setCategory('services')}>Services</button>
              <button data-category="financial" className={category === 'financial' ? 'active' : null} onClick={() => setCategory('financial')}>Financial</button>
              <button data-category="treatment" className={category === 'treatment' ? 'active' : null} onClick={() => setCategory('treatment')}>Treatment</button>
            </div>
            <div className="filterCheckboxes">
              {filterServices.map((value, i) => (
                <Checkbox key={i} label={value.label} value={value.value} category={'services'} />
              ))}
              {filterFinancial.map((value, i) => (
                <Checkbox key={i} label={value.label} value={value.value} category={'financial'} />
              ))}
              {filterTreatment.map((value, i) => (
                <Checkbox key={i} label={value.label} value={value.value} category={'treatment'} />
              ))}
            </div>
          </div>
          <div className={openResults ? 'right detailsShowing' : 'right detailsHidden'}>
            <div id="mapView">
              <div id="mapCanvas" ref={mapCanvas}></div>
              <div id="mapControls">
                <div id="zoomDiv" ref={zoomDiv}></div>
                <div id="sliderDiv" ref={sliderDiv}></div>
              </div>
            </div>
            <ResultsPanel />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
